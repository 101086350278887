body {
  color: #e3e3e3;
}
.navbar-custom {
  background: rgba(0,0,0,0.5);
}
.btn-default {
  border: 1px solid #fc0619;
  color: #fc0619;
  background-color: transparent;
}
.btn-default:hover,
.btn-default:focus {
  border: 1px solid #fc0619;
  outline: none;
  color: #000;
  background-color: #fc0619;
}
a:hover,
a:focus {
  text-decoration: none;
  color: #af0412;
}
.navbar-custom a {
  color: #fff;
}
ul.list-inline li a img {
  width: 100%;
  border: 2px solid #e3e3e3;
}
img.flag {
  width: 36px;
}
#galleries {
  min-height: 700px;
}
#follow {
  min-height: 710px;
}
@media (max-width: 769px) {
  .intro-body {
    padding-top: 240px;
  }
}
@media (max-width: 530px) {
  .intro-body {
    padding-top: 180px;
  }
}

.page-scroll a {
  color: white;

  &:hover {
    color: white;
  }
}